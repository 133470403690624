import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getKey } from '../helpers';
import BlogList from '../components/compound/BlogList';

/**
 * Tag Component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const Tag = props => {
  const metaTitle = getKey(props, 'pageContext.metaTitle');
  const description = getKey(props, 'pageContext.description');
  const posts = getKey(props, 'data.allHubspotPost.edges');
  const numberOfBlogPages = getKey(props, 'pageContext.numberOfCategoryPages');
  const currentBlogPage = getKey(props, 'pageContext.currentBlogPage');
  const slug = getKey(props, 'pageContext.slug');
  const topicId = getKey(props, 'pageContext.topicId');
  const pagination = `/blog/etiqueta/${topicId}/${slug}`;

  return (
    <>
      {metaTitle && (
        <Helmet
          title={metaTitle}
          meta={[
            { content: description, name: 'description' },
            { content: `${metaTitle}, alestra`, name: 'keywords' },
          ]}
        >
          <html lang="es" />
        </Helmet>
      )}
      <BlogList
        key={`blogPage${currentBlogPage}`}
        posts={posts}
        numberOfBlogPages={numberOfBlogPages}
        currentBlogPage={currentBlogPage}
        pageTitle={description}
        pagination={pagination}
      />
    </>
  );
};

export default Tag;

export const blogListQuery = graphql`
  query tagLisQuery($skip: Int!, $limit: Int!, $topicId: Float!) {
    allHubspotPost(
      sort: { fields: published, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        topics: {
          elemMatch: {
            topic_id: {
              eq: $topicId
            }
          }
        }
      }
    ) {
      edges {
        node {
          id
          slug
          title
          summary
          published_format(formatString: "D/M/YYYY")
          feature_image {
            alt_text
            url
          }
        }
      }
    }
  }
`;
